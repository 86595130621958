import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import PersonOutline from "@mui/icons-material/PersonOutline";
import TwoWheeler from "@mui/icons-material/TwoWheeler";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import MyLocationIcon from "@mui/icons-material/MyLocation";

import CallIcon from "@mui/icons-material/Call";
import RoomIcon from "@mui/icons-material/Room";
import ReceiptIcon from "@mui/icons-material/Receipt";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import { useHistory } from "react-router-dom";

import useLocalStorageState from 'use-local-storage-state';
import Notify from "@wcjiang/notify";

import { apiMotoboy, getCliente, getVersao } from "../services/apiMotoboy";

export default function DashboardPage(props) {
  let history = useHistory();
  /**
   *
   */
  const ref = React.useRef(null);
  const [token] = React.useState(localStorage.getItem("token"));
  const [usuario] = React.useState(JSON.parse(localStorage.getItem("usuario") || "{}"));
  const [type, setType] = React.useState(0);
  const [idEntregador, setIdEntregador] = React.useState("");
  const [idPedido, setIdPedido] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [previousList, setPreviousList] = useLocalStorageState('previousList', {
    defaultValue: 0,
  });
  const [total, setTotal] = React.useState({
    pedidos: 0,
    taxas: 0,
  });
  const [error, setError] = React.useState("");
  const [perfil, setPerfil] = React.useState(false);
  const [coordenadas, setCoordenadas] = useLocalStorageState('coordenadas', {
    defaultValue: {
      latitude: "",
      longitude: "",
    },
  });
  let timerItems = null;
  let timerCoordenadas = null;

  const setOnline = () => {
    setError("");
    console.info("[REDE] Sua conexão voltou.");
  };

  const setOffline = () => {
    setError("Sua internet está fraca, não será possível continuar!");
    console.info("[REDE] Sua conexão está fraca!");
  };

  const logout = () => {
    return history.push("/logout");
  };

  const showPerfil = () => {
    setPerfil(true);
  };

  const closePerfil = () => {
    setPerfil(false);
  };

  const redirectAdress = (endereco, newTab) => {
    console.info("Redirect to =>", endereco);

    if (newTab) {
      window.open(`${endereco}`);
      return;
    }
    window.location.href = endereco;
  };

  const Notificar = (qtd) => {
    var iN = new Notify({
      effect: "flash",
      interval: 500,
      audio: {
        file: "notify.wav",
      },
    });

    let limpar = () => {
      iN.faviconClear();
      iN.setTitle();
      iN.stopPlay();
    }

    if (qtd <= 0) {
      limpar();
      return;
    }

    iN.player();
    iN.setFavicon(qtd)
    iN.setTitle("Novos pedidos!");

    iN.notify({
      title: "Novos pedidos!",
      body: "Sua lista foi atualizada com novos pedidos para serem entregues.",
    });

    setTimeout(() => {
      limpar();
    }, 6000);
  }

  const getItems = (finalizados, checarQtd) => {
    if (timerItems) {
      let diff = Date.now() - timerItems;
      if (diff < 60000) {
        return;
      }
    }

    timerItems = Date.now();

    setLoading(true);
    return apiMotoboy
      .entregas()
      .then((res) => {
        setLoading(false);
        let { data } = res.data;
        let totalizador = {
          pedidos: 0,
          taxas: 0,
        };
        let pedidos = [];
        let pedidosFinalizados = [];
        data.map((pedido) => {
          switch (pedido.status) {
            case "Em Preparo":
              pedidos.push(pedido);
              break;
            case "Coletado":
            case "Conduzido":
            case "Saiu para entrega":
              pedidos.push(pedido);
              break;
            case "Entregue":
              totalizador.pedidos++;
              totalizador.taxas += Number(pedido.valor_entrega);
              pedidosFinalizados.push(pedido);
              break;
            case "Cancelado":
              pedidosFinalizados.push(pedido);
              break;
            default:
              break;
          }
          return pedido;
        });
        setTotal(totalizador);
        setList(finalizados ? pedidosFinalizados : pedidos);

        if (checarQtd) {
          if (pedidos.length !== previousList) {
            let qtd = Math.max(0, (pedidos.length - previousList));
            Notificar(qtd);

            setPreviousList(pedidos.length);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error?.response?.data?.body);
        console.error(error?.response);
      });
  };

  const resgatarPedido = (event) => {
    event.preventDefault();
    /**
     *
     */
    setError("");
    setLoading(true);
    apiMotoboy
      .resgatar({
        idPedido: idPedido,
        idEntregador: idEntregador,
      })
      .then(async (res) => {
        setLoading(false);
        if (type === 0) {
          await getItems();
        }
        setIdPedido("");
        setIdEntregador("");
        setPreviousList(previousList + 1);
      })
      .catch((error) => {
        setLoading(false);
        let { request } = error;
        let { responseText } = request;
        let response = JSON.parse(responseText);
        let { errors } = response;
        setError(errors);
        console.error(error);
      });
  };

  const changeSituationDelivery = (id, codigo) => {
    setError("");
    setLoading(true);
    return apiMotoboy
      .alterarSituacaoEntrega({
        idPedido: id,
      })
      .then(async (res) => {
        setLoading(false);

        if (codigo) {
          confirmarIfood(codigo);
        }

        await getItems();
      })
      .catch((error) => {
        setLoading(false);
        let { request } = error;
        let { responseText } = request;
        let response = JSON.parse(responseText);
        let { errors } = response;
        setError(errors);
        console.error(error);
      });
  };

  const changeList = (newType) => {
    switch (newType) {
      case 0:
        setType(newType);
        getItems();
        break;
      case 1:
        setType(newType);
        getItems(true);
        break;
      default:
        showPerfil();
        break;
    }
    /**
     *
     */
    setError("");
  };

  const visualizarComprovante = (comprovante) => {
    let tab = window.open("about:blank", "_blank");
    tab.document.write(`<html>
      <head>
      <title>Impressão</title>
      <style>
          body {
              font-family: Calibri;
              font-size: 14px;
              white-space: pre-wrap;
              margin: 10px;
              padding: 10px;
              height: 100%;
              width: 255px;
          }
          </style>
      </head>

      <body>
      ${comprovante}
      <br />
      </body>
      </html>`);
    tab.document.close();
  };

  const confirmarIfood = (codigo) => {
    alert(`Código: ${codigo}`);
    window.open("https://confirmacao-entrega-propria.ifood.com.br/", "_blank");
  };

  const atualizarCoordenadas = () => {
    if (timerCoordenadas) {
      let diff = Date.now() - timerCoordenadas;
      if (diff < 15000) {
        return;
      }
    }

    timerCoordenadas = Date.now();

    let doSucess = (position) => {
      const posicao = {
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
      };

      if (
        (!posicao.latitude && !posicao.longitude) ||
        (coordenadas.latitude === posicao.latitude &&
          coordenadas.longitude === posicao.longitude)
      ) {
        return;
      }

      return apiMotoboy
        .atualizarCoordenadas({
          latitude: position?.coords?.latitude,
          longitude: position?.coords?.longitude,
        })
        .then(async (res) => {
          setCoordenadas(posicao);
          console.info("Coordenadas atualizadas com sucesso.");
        })
        .catch((error) => {
          console.error(error);
        });
    };

    let doError = (error) => {
      setError("Precisamos que você ative sua localização.");
      console.error(error);
    };

    navigator.geolocation.getCurrentPosition(doSucess, doError);
  };

  React.useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);
    /**
     *
     */
    if (!token) {
      history.push("/logout");
    }
    /**
     *
     */
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);
    /**
     *
     */
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, [token, history]);

  React.useEffect(() => {
    atualizarCoordenadas();
    const timer = setInterval(() => {
      atualizarCoordenadas();
    }, 15000);

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getItems(false, true);
    const timer = setInterval(() => {
      getItems(false, true);
    }, 60000);

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#0096FF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={perfil}
        onClose={closePerfil}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Olá {usuario.nome}!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body2" component="span" sx={{ mt: 3 }}>
              Você já realizou {total.pedidos} entrega(s) hoje.{"\n"}{"\n"}
              <br />
              <br />
              {getVersao()} - {getCliente()}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout}>Sair</Button>
          <Button onClick={closePerfil} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit" component="div">
              Entregas
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      <Box
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", m: 1 }}
        onSubmit={resgatarPedido}
        noValidate
        autoComplete="off">
        <TextField
          id="filled-basic"
          label="Entregador (opcional)"
          placeholder="Qual o ID do entregador?"
          type="number"
          sx={{ m: 1 }}
          onInput={(e) => setIdEntregador(e.target.value)}
          variant="filled"
          value={idEntregador}
          disabled={loading}
          fullWidth
        />
        <TextField
          id="filled-basic"
          label="Pedido*"
          placeholder="Qual o ID do pedido?"
          type="number"
          sx={{ m: 1 }}
          onInput={(e) => setIdPedido(e.target.value)}
          variant="filled"
          value={idPedido}
          disabled={loading}
          fullWidth
          autoFocus
        />

        <IconButton
          color="primary"
          sx={{ p: "10px" }}
          disabled={loading || !idPedido.length}
          type="submit"
          aria-label="search">
          <SearchIcon />
        </IconButton>
      </Box>

      {error ? (
        <Box sx={{ p: 1 }}>
          <Alert severity="warning">{error}</Alert>
        </Box>
      ) : (
        ""
      )}

      {!list.length && !loading ? (
        <Box sx={{ p: 1 }}>
          <Typography sx={{ m: 1.5 }} color="text.secondary">
            Ainda não temos nenhuma entrega, aguarde...
          </Typography>
        </Box>
      ) : (
        ""
      )}
      <List>
        {list.map(
          (
            {
              id,
              numero,
              id_ifood,
              hora,
              nome_ifood,
              tel_ifood,
              tel_id_ifood,
              nomeCliente,
              telCliente,
              formaDePagamento,
              valor,
              distancia,
              cep,
              end,
              bairro,
              nm,
              complemento,
              localidade,
              entregador_finalizou,
              status,
              comprovante,
            },
            index
          ) => (
            <Card sx={{ minWidth: 275, m: 1 }} key={index} square={true}>
              <CardContent>
                <Box>
                  <Chip label={hora} icon={<AccessTimeIcon />} size="small" />
                  <Chip
                    sx={{ m: 0.4 }}
                    label={Number(valor).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                    size="small"
                    color="success"
                    variant="outlined"
                  />
                  <Chip
                    label={formaDePagamento}
                    size="small"
                    variant="outlined"
                  />
                  <Chip label={(distancia / 1000).toFixed(1) + ` km`} icon={<MyLocationIcon />} size="small" />
                </Box>
                <Typography variant="h5" component="div">
                  {numero} - {nome_ifood ? nome_ifood : nomeCliente} #{id}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {tel_ifood
                    ? `Código do Ifood: ${tel_id_ifood}`
                    : telCliente}
                </Typography>
                <Typography variant="body2">
                  {end} {nm}, {bairro} {complemento ? `- ${complemento}` : ""}
                </Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                {entregador_finalizou ||
                  ["Entregue", "Cancelado"].includes(status) ? (
                  <Button
                    size="small"
                    onClick={() => changeSituationDelivery(id)}
                    disabled={["Entregue", "Cancelado"].includes(status)}
                    color="success">
                    <CheckCircleOutlineIcon />
                  </Button>
                ) : (
                  <>
                    <Button size="small" onClick={() => visualizarComprovante(comprovante)}>
                      <ReceiptIcon />
                    </Button>
                    <Button
                      size="small"
                      onClick={() =>
                        redirectAdress(
                          `tel:${tel_ifood ? tel_ifood : telCliente}`
                        )
                      }>
                      <CallIcon />
                    </Button>
                    {id_ifood ?
                      <Button
                        size="small"
                        onClick={() =>
                          confirmarIfood(tel_id_ifood)
                        }>
                        <DeliveryDiningIcon />
                      </Button>
                      : (
                        <Button
                          size="small"
                          onClick={() =>
                            redirectAdress(
                              `https://wa.me/55${telCliente.replace(/\D/g, "")}`,
                              true
                            )
                          }>
                          <WhatsAppIcon />
                        </Button>
                      )}
                    {
                      <Button
                        size="small"
                        onClick={() =>
                          redirectAdress(
                            encodeURI(
                              `https://www.google.com/maps/dir/?api=1&travelmode=driving&language=pt-BR&destination=${end}, ${nm} - ${localidade}${cep ? `, ${cep}` : ""
                              }`
                            ),
                            true
                          )
                        }>
                        <RoomIcon />
                      </Button>
                    }
                    <Button
                      size="small"
                      onClick={() => changeSituationDelivery(id, (id_ifood && !entregador_finalizou) ? tel_id_ifood : null)}>
                      <RadioButtonUncheckedIcon />
                    </Button>
                  </>
                )}
              </CardActions>
            </Card>
          )
        )}
      </List>

      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}>
        <BottomNavigation
          showLabels
          value={type}
          onChange={(event, newValue) => {
            changeList(newValue);
          }}>
          <BottomNavigationAction label="Pendentes" icon={<TwoWheeler />} />
          <BottomNavigationAction label="Finalizados" icon={<RestoreIcon />} />
          <BottomNavigationAction label="Perfil" icon={<PersonOutline />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
